/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const TurnoverCostCalculator = () => {
  // State for direct costs
  const [directCosts, setDirectCosts] = useState({
    salary: 75000, // Annual salary of departed employee
    recruitingCosts: 5000, // Job posting, recruiter fees, etc.
    interviewingTime: 20, // Hours spent interviewing
    onboardingTime: 40, // Hours spent on onboarding
    trainingTime: 80, // Hours spent on training
    hourlyRate: 50, // Average hourly rate for managers/staff involved
  })

  // State for indirect costs
  const [indirectCosts, setIndirectCosts] = useState({
    productivityLoss: 90, // Days until new hire reaches full productivity
    teamProductivityImpact: 15, // Percentage impact on team productivity
    customerServiceImpact: 10, // Percentage impact on customer service
    revenueImpact: 5, // Percentage impact on revenue generation
  })

  // Calculate total costs
  const calculateTotalCosts = () => {
    // Direct costs calculation
    const interviewCost = directCosts.interviewingTime * directCosts.hourlyRate
    const onboardingCost = directCosts.onboardingTime * directCosts.hourlyRate
    const trainingCost = directCosts.trainingTime * directCosts.hourlyRate
    
    const totalDirectCosts = directCosts.recruitingCosts + interviewCost + 
      onboardingCost + trainingCost

    // Indirect costs calculation
    const dailyRevenue = (directCosts.salary * 2.5) / 260 // Estimated daily revenue impact
    const productivityLossCost = (dailyRevenue * indirectCosts.productivityLoss) * 
      (indirectCosts.teamProductivityImpact / 100)
    
    const customerImpactCost = (dailyRevenue * 30) * // 30 days impact period
      (indirectCosts.customerServiceImpact / 100)
    
    const revenueLossCost = (dailyRevenue * 60) * // 60 days impact period
      (indirectCosts.revenueImpact / 100)

    const totalIndirectCosts = productivityLossCost + customerImpactCost + revenueLossCost

    return {
      directCosts: totalDirectCosts,
      indirectCosts: totalIndirectCosts,
      totalCost: totalDirectCosts + totalIndirectCosts
    }
  }

  const handleDirectCostChange = (field, value) => {
    setDirectCosts(prev => ({
      ...prev,
      [field]: Number(value)
    }))
  }

  const handleIndirectCostChange = (field, value) => {
    setIndirectCosts(prev => ({
      ...prev,
      [field]: Number(value)
    }))
  }

  const costs = calculateTotalCosts()

  return (
    <Layout className="turnover-calculator-page">
      <SEO 
        title="Employee Turnover Cost Calculator" 
        description="Calculate the true cost of employee turnover including direct and indirect costs, productivity loss, and business impact."
      />
      <div className="wrapper" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
        <h1 sx={{ fontSize: ['1.75rem', '2rem', '2.25rem'], mb: 4 }}>
          Employee Turnover Cost Calculator
        </h1>

        {/* Description Section */}
        <div sx={{ 
          bg: 'white', 
          p: 4, 
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
          mb: 4
        }}>
          <h2 sx={{ fontSize: '1.3rem', mb: 3, color: 'primary' }}>About This Calculator</h2>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
            Employee turnover can significantly impact an organization's bottom line through both direct and indirect costs. 
            This calculator helps you estimate the true financial impact of losing and replacing an employee by considering:
          </p>
          <ul sx={{ 
            listStyle: 'disc',
            pl: 4,
            mb: 3,
            '& li': { 
              mb: 2,
              fontSize: '1rem',
              lineHeight: '1.5'
            }
          }}>
            <li>Direct costs such as recruiting expenses, interviewing time, and training resources</li>
            <li>Indirect costs including productivity loss, team impact, and customer service disruption</li>
            <li>Long-term effects on revenue and business performance</li>
          </ul>
          <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
            Use this calculator to make informed decisions about retention strategies and understand the importance of maintaining a stable workforce.
          </p>
        </div>

        <div sx={{ 
          display: 'grid', 
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'], 
          gap: 4,
          bg: 'white',
          p: 4,
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          {/* Direct Costs Section */}
          <div>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Direct Costs</h2>
            <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <div>
                <label htmlFor="salary" sx={{ display: 'block', mb: 2 }}>
                  Annual Salary
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Base salary of the departed employee
                  </span>
                </label>
                <input
                  id="salary"
                  type="number"
                  value={directCosts.salary}
                  onChange={(e) => handleDirectCostChange('salary', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
              <div>
                <label htmlFor="recruitingCosts" sx={{ display: 'block', mb: 2 }}>
                  Recruiting Costs
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Job postings, recruiter fees, advertising
                  </span>
                </label>
                <input
                  id="recruitingCosts"
                  type="number"
                  value={directCosts.recruitingCosts}
                  onChange={(e) => handleDirectCostChange('recruitingCosts', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
              <div>
                <label htmlFor="interviewingTime" sx={{ display: 'block', mb: 2 }}>
                  Interviewing Time (Hours)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Total hours spent by staff on interviews
                  </span>
                </label>
                <input
                  id="interviewingTime"
                  type="number"
                  value={directCosts.interviewingTime}
                  onChange={(e) => handleDirectCostChange('interviewingTime', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
              <div>
                <label htmlFor="hourlyRate" sx={{ display: 'block', mb: 2 }}>
                  Average Hourly Rate
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Average cost per hour for staff involved
                  </span>
                </label>
                <input
                  id="hourlyRate"
                  type="number"
                  value={directCosts.hourlyRate}
                  onChange={(e) => handleDirectCostChange('hourlyRate', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
              <div>
                <label htmlFor="onboardingTime" sx={{ display: 'block', mb: 2 }}>
                  Onboarding Time (Hours)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Hours spent on new hire paperwork and setup
                  </span>
                </label>
                <input
                  id="onboardingTime"
                  type="number"
                  value={directCosts.onboardingTime}
                  onChange={(e) => handleDirectCostChange('onboardingTime', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
              <div>
                <label htmlFor="trainingTime" sx={{ display: 'block', mb: 2 }}>
                  Training Time (Hours)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Hours spent training the new hire
                  </span>
                </label>
                <input
                  id="trainingTime"
                  type="number"
                  value={directCosts.trainingTime}
                  onChange={(e) => handleDirectCostChange('trainingTime', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
            </div>
          </div>

          {/* Indirect Costs Section */}
          <div>
            <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Indirect Costs</h2>
            <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <div>
                <label htmlFor="productivityLoss" sx={{ display: 'block', mb: 2 }}>
                  Days Until Full Productivity
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Time until new hire reaches optimal performance
                  </span>
                </label>
                <input
                  id="productivityLoss"
                  type="number"
                  value={indirectCosts.productivityLoss}
                  onChange={(e) => handleIndirectCostChange('productivityLoss', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
              <div>
                <label htmlFor="teamProductivityImpact" sx={{ display: 'block', mb: 2 }}>
                  Team Productivity Impact (%)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Reduction in team efficiency during transition
                  </span>
                </label>
                <input
                  id="teamProductivityImpact"
                  type="number"
                  value={indirectCosts.teamProductivityImpact}
                  onChange={(e) => handleIndirectCostChange('teamProductivityImpact', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
              <div>
                <label htmlFor="customerServiceImpact" sx={{ display: 'block', mb: 2 }}>
                  Customer Service Impact (%)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Impact on customer satisfaction and service quality
                  </span>
                </label>
                <input
                  id="customerServiceImpact"
                  type="number"
                  value={indirectCosts.customerServiceImpact}
                  onChange={(e) => handleIndirectCostChange('customerServiceImpact', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
              <div>
                <label htmlFor="revenueImpact" sx={{ display: 'block', mb: 2 }}>
                  Revenue Impact (%)
                  <span sx={{ display: 'block', fontSize: '0.875rem', color: 'gray.6' }}>
                    Estimated impact on revenue generation
                  </span>
                </label>
                <input
                  id="revenueImpact"
                  type="number"
                  value={indirectCosts.revenueImpact}
                  onChange={(e) => handleIndirectCostChange('revenueImpact', e.target.value)}
                  sx={{
                    width: '100%',
                    p: 2,
                    border: '1px solid',
                    borderColor: 'gray.3',
                    borderRadius: '4px'
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Results Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 3, color: 'primary' }}>Total Cost Impact</h2>
          <div sx={{ display: 'grid', gridTemplateColumns: ['1fr', '1fr 1fr 1fr'], gap: 4 }}>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Direct Costs</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                ${costs.directCosts.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Indirect Costs</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                ${costs.indirectCosts.toLocaleString()}
              </p>
            </div>
            <div>
              <h3 sx={{ fontSize: '1.1rem', color: 'gray.7' }}>Total Cost</h3>
              <p sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: 'primary' }}>
                ${costs.totalCost.toLocaleString()}
              </p>
            </div>
          </div>
        </div>

        {/* FAQ Section */}
        <div sx={{ 
          mt: 4,
          p: 4,
          bg: 'white',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
        }}>
          <h2 sx={{ fontSize: '1.5rem', mb: 4, color: 'primary' }}>Expert Guide: Employee Turnover Costs</h2>
          
          <div sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 3, color: 'primary' }}>
                Understanding Employee Turnover Costs: Key Statistics
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
                According to recent industry research:
              </p>
              <ul sx={{ 
                listStyle: 'disc',
                pl: 4,
                mb: 3,
                '& li': { 
                  mb: 2,
                  fontSize: '1rem',
                  lineHeight: '1.5'
                }
              }}>
                <li>The average cost of employee turnover ranges from 50% to 200% of the employee's annual salary (SHRM, 2023)</li>
                <li>Companies lose approximately $15,000 per worker in direct replacement costs</li>
                <li>Indirect costs can amount to 2.5 times the salary of the departed employee due to lost productivity and knowledge</li>
              </ul>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 3, color: 'primary' }}>
                How Our Calculator Determines Total Turnover Cost
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
                Our calculator uses a comprehensive methodology based on research from SHRM, Gallup, and industry experts to calculate:
              </p>
              <div sx={{ pl: 4, mb: 3 }}>
                <h4 sx={{ fontSize: '1.1rem', fontWeight: 'bold', mb: 2 }}>Direct Costs:</h4>
                <ul sx={{ 
                  listStyle: 'disc',
                  pl: 4,
                  mb: 3,
                  '& li': { 
                    mb: 1,
                    fontSize: '1rem',
                    lineHeight: '1.5'
                  }
                }}>
                  <li>Recruiting costs (job postings, agency fees)</li>
                  <li>Interview process time and resources</li>
                  <li>Onboarding and training expenses</li>
                  <li>Administrative processing costs</li>
                </ul>

                <h4 sx={{ fontSize: '1.1rem', fontWeight: 'bold', mb: 2 }}>Indirect Costs:</h4>
                <ul sx={{ 
                  listStyle: 'disc',
                  pl: 4,
                  '& li': { 
                    mb: 1,
                    fontSize: '1rem',
                    lineHeight: '1.5'
                  }
                }}>
                  <li>Lost productivity during vacancy</li>
                  <li>Team productivity impact</li>
                  <li>Customer service disruption</li>
                  <li>Revenue generation impact</li>
                </ul>
              </div>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 3, color: 'primary' }}>
                Industry-Specific Cost Variations
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
                Turnover costs vary significantly by industry. Recent studies show:
              </p>
              <ul sx={{ 
                listStyle: 'disc',
                pl: 4,
                '& li': { 
                  mb: 2,
                  fontSize: '1rem',
                  lineHeight: '1.5'
                }
              }}>
                <li><strong>Technology:</strong> 100-150% of salary due to high competition and specialized skills</li>
                <li><strong>Healthcare:</strong> 150-200% of salary due to certification requirements and patient care impact</li>
                <li><strong>Retail/Service:</strong> 50-75% of salary due to standardized roles but high training needs</li>
                <li><strong>Professional Services:</strong> 200-400% of salary due to client relationship impact</li>
              </ul>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 3, color: 'primary' }}>
                Maximizing Calculator Accuracy
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
                For the most accurate results:
              </p>
              <ol sx={{ 
                pl: 4,
                '& li': { 
                  mb: 2,
                  fontSize: '1rem',
                  lineHeight: '1.5'
                }
              }}>
                <li><strong>Salary Input:</strong> Include total compensation (base salary + benefits, typically 1.25-1.4x base salary)</li>
                <li><strong>Time Metrics:</strong> Track actual hours spent on interviews, onboarding, and training</li>
                <li><strong>Productivity Impact:</strong> Consider team size and interconnected roles</li>
                <li><strong>Revenue Effects:</strong> Account for both direct revenue impact and customer relationship value</li>
              </ol>
            </div>

            <div>
              <h3 sx={{ fontSize: '1.2rem', fontWeight: 'bold', mb: 3, color: 'primary' }}>
                Evidence-Based Retention Strategies
              </h3>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 3 }}>
                Research-backed approaches to reduce turnover costs:
              </p>
              <div sx={{ pl: 4 }}>
                <h4 sx={{ fontSize: '1.1rem', fontWeight: 'bold', mb: 2 }}>Immediate Impact (0-3 months):</h4>
                <ul sx={{ 
                  listStyle: 'disc',
                  pl: 4,
                  mb: 3,
                  '& li': { 
                    mb: 1,
                    fontSize: '1rem',
                    lineHeight: '1.5'
                  }
                }}>
                  <li>Conduct stay interviews with key employees</li>
                  <li>Review and adjust compensation packages</li>
                  <li>Implement flexible work arrangements</li>
                </ul>

                <h4 sx={{ fontSize: '1.1rem', fontWeight: 'bold', mb: 2 }}>Long-term Solutions (3-12 months):</h4>
                <ul sx={{ 
                  listStyle: 'disc',
                  pl: 4,
                  '& li': { 
                    mb: 1,
                    fontSize: '1rem',
                    lineHeight: '1.5'
                  }
                }}>
                  <li>Develop clear career progression frameworks</li>
                  <li>Establish mentorship programs</li>
                  <li>Create skill development opportunities</li>
                  <li>Build comprehensive onboarding programs</li>
                </ul>
              </div>
            </div>

            <div sx={{ 
              mt: 3,
              p: 3,
              bg: 'gray.1',
              borderRadius: '4px',
              borderLeft: '4px solid',
              borderLeftColor: 'primary'
            }}>
              <h4 sx={{ fontSize: '1.1rem', fontWeight: 'bold', mb: 2 }}>Expert Tip</h4>
              <p sx={{ fontSize: '1rem', lineHeight: '1.6' }}>
                "The true cost of turnover extends beyond immediate financial impact. Organizations should focus on 
                creating a retention-first culture, as every 1% reduction in turnover can lead to a 5-10% reduction 
                in overall turnover costs." - Dr. Sarah Chen, Organizational Psychology Expert
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TurnoverCostCalculator 